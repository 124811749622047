import dynamic from 'next/dynamic'
import Head from 'next/head'
import nookies from 'nookies'
import { wrapper } from '@redux/store'
import { fetchLang } from '@redux/modules/global'
import DashboardLayout from '@shared/layouts/Dashboard'
import { loadTranslations } from '@utils/translations'

const ContainerHome = dynamic(() => import('@containers/Home'), { ssr: false })

export default function Home(props) {
  return (
    <>
      <Head>
        <title>Apolo</title>
        <meta name="description" content="Apolo, CMS Asdeporte" />
      </Head>
      <ContainerHome {...props} />
    </>
  )
}

Home.getLayout = DashboardLayout

export const getServerSideProps = wrapper.getServerSideProps(
  store => async ctx => {
    const cookies = nookies.get(ctx)
    const lang = cookies?.['lang'] ?? 'es'
    store.dispatch(fetchLang({ lang }))
    return {
      props: {
        lang,
        ...(await loadTranslations(lang, ['page-home', 'common'])),
      },
    }
  }
)
